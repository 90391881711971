import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { Seo, Layout } from "../components"
import { StaticImage } from "gatsby-plugin-image"
import { Email } from "react-obfuscate-email"

const MentionsLegales = () => {
  return (
    <Layout>
      <Seo title="Koomet - Mentions légales" 
      desc="Mentions légales de l'agence web Koomet basée à Bourges et Saint-Amand-Montrond."
      breadcrumb
      bread1name="Accueil"
      bread1url="/"
      bread2name="Mentions légales"
      bread2url="/mentions-legales/"/>
      <div className="flex flex-col">
        <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
          <div className="py-16">
            <div className="text-center text-3xl font-extrabold text-gray-700 pb-10 dark:text-white">
              <h1>Mentions légales de l'agence digitale en ligne</h1>
            </div>
            <h2 className="text-xl mb-5 font-extrabold text-violet sm:text-2xl">1. Présentation du site web.</h2>
            <p className="mb-3">En vertu de l'article 6 de la loi n° 2004-575 du 21 juin 2004 pour la confiance dans l'économie numérique, il est précisé aux utilisateurs du site internet www.koomet.fr l'identité des différents intervenants dans le cadre de sa réalisation et de son suivi :</p>
            <p><strong>Koomet</strong> est une agence digitale en ligne Française <StaticImage
              className="mt-1"
              width="16"
              src="../assets/france.png"
              quality={100}
              alt="O2Switch Hébergeur Français"
            /> spécialisée dans la création / refonte de sites internet, le référencement naturel (SEO). </p>
            <p><strong>Propriétaire :</strong> Nicolas LEZZI - SIREN: 892247677 SIRET: 89224767700024 - 171 Rond-Point Albert Donnezan - 66000 Perpignan </p>
            <p><strong>Responsable de la publication :</strong> Koomet - <AniLink className="text-violet" cover bg="#6377E9" direction="up" duration={0.6} to="/contact/">(nous contacter)</AniLink></p>
            <p><strong>Délégué à la protection des données :</strong> Nicolas LEZZI - <Email email="contact@koomet.fr">📧 Cliquez ici !</Email></p>
            <p><strong>Hébergeur :</strong> O2Switch
              <StaticImage
                className="mt-1 ml-1"
                width="16"
                src="../assets/france.png"
                quality={100}
                alt="O2Switch Hébergeur Français"
              /> - 222 Bd Gustave Flaubert, 63000 Clermont-Ferrand</p>
            <p className="mb-3 mt-3 font-bold">Crédits photographiques :</p>
            <ul className="list-disc pl-10">
              <li>Page d'accueil : Vidéo Videezy.com</li>
              <li>Page "Maintenance de site Internet", "Référencement de Site Internet" : Image par rawpixel.com</li>
              <li>Page "Agence Saint-Amand-Montrond" : Photographie de la Ville de Saint-Amand-Montrond</li>
              <li>Page "Agence Paris" : Photographie par @cyril_m sur Unsplash</li>
              <li>Page "Agence Bordeaux" : Photographie par @clo_shooting sur Unsplash</li>
            </ul>
            <h2 className="text-xl mt-5 mb-5 font-extrabold text-violet sm:text-2xl">2. Conditions générales d’utilisation du site et des services proposés.</h2>
            <p>L’utilisation du site www.koomet.fr implique l’acceptation pleine et entière des conditions générales d’utilisation ci-après décrites. Ces conditions d’utilisation sont susceptibles d’être modifiées ou complétées à tout moment, les utilisateurs du site www.koomet.fr sont donc invités à les consulter de manière régulière.</p>
            <p className="mt-3">Le site web www.koomet.fr est mis à jour régulièrement par le responsable de publication. De la même façon, les mentions légales peuvent être modifiées à tout moment : elles s’imposent néanmoins à l’utilisateur qui est invité à s’y référer le plus souvent possible afin d’en prendre connaissance.</p>
            <h2 className="text-xl mt-5 mb-5 font-extrabold text-violet sm:text-2xl">3. Description des services.</h2>
            <p>Le site internet www.koomet.fr a pour objet de fournir une information concernant l’ensemble des activités de la société, ici, agence digitale en ligne proposant la conception, refonte de sites Internet, prestations de référencement. Le responsable de publication s’efforce de fournir sur le site www.koomet.fr des informations aussi précises que possible. Toutefois, il ne pourra être tenu responsable des oublis, des inexactitudes et des carences dans la mise à jour, qu’elles soient de son fait ou du fait des tiers partenaires qui lui fournissent ces informations.</p>
            <p className="mt-3">Toutes les informations indiquées sur le site www.koomet.fr sont données à titre indicatif, et sont susceptibles d’évoluer. Par ailleurs, les renseignements figurant sur le site www.koomet.fr ne sont pas exhaustifs. Ils sont donnés sous réserve de modifications ayant été apportées depuis leur mise en ligne.</p>
            <h2 className="text-xl mt-5 mb-5 font-extrabold text-violet sm:text-2xl">4. Limitations contractuelles sur les données techniques.</h2>
            <p>Le présent site utilise la technologie Gatsby JS ainsi que d'autres technologies JavaScript.</p>
            <p className="mt-3">Le site www.koomet.fr est hébergé chez un prestataire sur le territoire de l’Union Européenne conformément aux dispositions du Règlement Général sur la Protection des Données (RGPD : n° 2016-679)</p>
            <p>L’objectif est d’apporter une prestation qui assure le meilleur taux d’accessibilité. L’hébergeur assure la continuité de son service 24 Heures sur 24, tous les jours de l’année. Il se réserve néanmoins la possibilité d’interrompre le service d’hébergement pour les durées les plus courtes possibles notamment à des fins de maintenance, d’amélioration de ses infrastructures, de défaillance de ses infrastructures ou si les Prestations et Services génèrent un trafic réputé anormal.</p>
            <p className="mt-3">www.koomet.fr et l’hébergeur ne pourront être tenus responsables en cas de dysfonctionnement du réseau Internet, des lignes téléphoniques ou du matériel informatique et de téléphonie lié notamment à l’encombrement du réseau empêchant l’accès au serveur.</p>
            <h2 className="text-xl mt-5 mb-5 font-extrabold text-violet sm:text-2xl">5. Propriété intellectuelle et contrefaçons.</h2>
            <p>Le responsable de publication est propriétaire des droits de propriété intellectuelle et détient les droits d’usage sur tous les éléments accessibles sur le site internet, notamment les textes, images, graphismes, logos, vidéos, icônes et sons. Toute reproduction, représentation, modification, publication, adaptation de tout ou partie des éléments du site, quel que soit le moyen ou le procédé utilisé, est interdite, sauf autorisation écrite préalable du responsable de publication.</p>
            <p className="mt-3">Toute exploitation non autorisée du site ou de l’un quelconque des éléments qu’il contient sera considérée comme constitutive d’une contrefaçon et poursuivie conformément aux dispositions des articles L.335-2 et suivants du Code de Propriété Intellectuelle.</p>
            <h2 className="text-xl mt-5 mb-5 font-extrabold text-violet sm:text-2xl">6. Limitations de responsabilité.</h2>
            <p>Le responsable de publication ne pourra être tenu responsable des dommages directs et indirects causés au matériel de l’utilisateur, lors de l’accès au site internet www.koomet.fr, et résultant soit de l’utilisation d’un matériel ne répondant pas aux spécifications indiquées au point 4, soit de l’apparition d’un bug ou d’une incompatibilité.</p>
            <p className="mt-3">Le responsable de publication ne pourra également être tenu responsable des dommages indirects (tels par exemple qu’une perte de marché ou perte d’une chance) consécutifs à l’utilisation du site www.koomet.fr. Des espaces interactifs (possibilité de poser des questions dans l’espace contact) sont à la disposition des utilisateurs. www.koomet.fr se réserve le droit de supprimer, sans mise en demeure préalable, tout contenu déposé dans cet espace qui contreviendrait à la législation applicable en France, en particulier aux dispositions relatives à la protection des données. Le cas échéant, le responsable de publication se réserve également la possibilité de mettre en cause la responsabilité civile et/ou pénale de l’utilisateur, notamment en cas de message à caractère raciste, injurieux, diffamant, ou pornographique, quel que soit le support utilisé (texte, photographie …).</p>
          </div>
        </main>
      </div>
    </Layout>
  )
}

export default MentionsLegales
